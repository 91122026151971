import React, { useState } from 'react';
import { fetchEvents } from '../api';

function DateHistory() {
  const [selectedDate, setSelectedDate] = useState('');
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDateSearch = async () => {
    if (!selectedDate) return;

    setIsLoading(true);
    try {
      const queryParams = {
        date: selectedDate
      };
      const fetchedEvents = await fetchEvents(queryParams);
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error fetching events for date:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="date-history-container">
      <div className="date-search">
        <input 
          type="date" 
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <button onClick={handleDateSearch}>
          Buscar Procedimentos
        </button>
      </div>

      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <table className="date-events-table">
          <thead>
            <tr>
              <th>Hora</th>
              <th>Paciente</th>
              <th>Procedimento</th>
              <th>Profissional</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.start ? event.start.S.split('T')[1] : 'N/A'}</td>
                <td>{event.patient ? event.patient.S : 'N/A'}</td>
                <td>{event.procedure ? event.procedure.S : 'N/A'}</td>
                <td>{event.professional ? event.professional.S : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {events.length === 0 && !isLoading && (
        <div className="no-data">
          <p>Nenhum procedimento encontrado para esta data</p>
        </div>
      )}
    </div>
  );
}

export default DateHistory; 