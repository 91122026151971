import React, { useState, useCallback, useMemo, useImperativeHandle, forwardRef } from 'react';
import { fetchPatients } from '../api';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import './PacientSearchSelect.css';

const PacientSearchSelect = forwardRef(({ onPatientSelect }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);

  // Expose resetSearch method to parent component
  useImperativeHandle(ref, () => ({
    resetSearch: () => {
      setSearchTerm('');
      setPatients([]);
      setSelectedPatient(null);
      setHasSearched(false);
    }
  }));

  // Debounced search function
  const debouncedSearch = useMemo(
    () => debounce(async (term) => {
      if (!term || term.length < 3) {
        setPatients([]);
        setHasSearched(false);
        return;
      }

      setIsLoading(true);
      try {
        const results = await fetchPatients(term);
        setPatients(results);
        setHasSearched(true);
      } catch (error) {
        console.error('Patient search error:', error);
        setPatients([]);
        setHasSearched(true);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // Memoized search handler
  const handleSearch = useCallback((e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    // If a patient was selected, clicking to type should clear the selection
    if (selectedPatient) {
      setSelectedPatient(null);
    }
    
    debouncedSearch(term);
  }, [debouncedSearch, selectedPatient]);

  // Memoized patient selection
  const handlePatientSelection = useCallback((patient) => {
    onPatientSelect(patient);
    setSelectedPatient(patient);
    setSearchTerm('');
    setPatients([]);
  }, [onPatientSelect]);

  return (
    <div className="patient-search-container">
      <div className="search-input-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={selectedPatient ? selectedPatient.Nome : "Digite o nome do paciente"}
          className="search-input"
        />
      </div>

      {isLoading && <div className="loading-indicator">Carregando...</div>}

      {patients.length > 0 && (
        <div className="patient-dropdown">
          {patients.map((patient) => (
            <div
              key={patient.pacientId} 
              onClick={() => handlePatientSelection(patient)}
              className="patient-option"
            >
              <span className="patient-name">{patient.Nome}</span>
              {patient.CPF && <span className="patient-cpf">CPF: {patient.CPF}</span>}
            </div>
          ))}
          <Link to="/pacientes" className="patient-option new-patient-option">
            <span className="patient-name">Cadastrar novo paciente</span>
          </Link>
        </div>
      )}

      {hasSearched && !isLoading && patients.length === 0 && (
        <div className="no-results">
          <div>Nenhum paciente encontrado</div>
          <Link to="/pacientes" className="new-patient-link">
            Cadastrar novo paciente +
          </Link>
        </div>
      )}
    </div>
  );
});

export default PacientSearchSelect;
