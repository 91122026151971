import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { getStock } from '../api';
import './Estoque.css';

function InvoiceModal({ show, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        nf_number: '',
        date: new Date().toISOString().split('T')[0],
        supplier: '',
        notes: '',
        taxes: '',
        shipping: '',
        items: []
    });
    const [productId, setProductId] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (!show) {
            setFormData({
                nf_number: '',
                date: new Date().toISOString().split('T')[0],
                supplier: '',
                notes: '',
                taxes: '',
                shipping: '',
                items: []
            });
            setProductId('');
            setSelectedQuantity(1);
            setSelectedPrice('');
            setError('');
        }
    }, [show]);

    // Add keyboard event handler
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleAddItem = async () => {
        if (!productId || !selectedPrice) {
            setError('Preencha o código do item e o preço');
            return;
        }

        try {
            setError('');
            const stockData = await getStock(productId);
            
            if (!stockData || !stockData.items || stockData.items.length === 0) {
                setError('Item não encontrado no estoque');
                return;
            }

            const stockItem = stockData.items[0];
            setFormData(prev => ({
                ...prev,
                items: [
                    ...prev.items,
                    {
                        product_id: productId,
                        description: stockItem.description,
                        quantity: selectedQuantity,
                        price: parseFloat(selectedPrice)
                    }
                ]
            }));

            setProductId('');
            setSelectedQuantity(1);
            setSelectedPrice('');
        } catch (err) {
            setError('Erro ao buscar item no estoque');
            console.error(err);
        }
    };

    const handleRemoveItem = (index) => {
        setFormData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.items.length === 0) {
            alert('Adicione pelo menos um item à nota fiscal');
            return;
        }
        onSubmit(formData);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className="modal-overlay" 
            onClick={handleOverlayClick}
        >
            <div className="modal-content">
                <h2>Nova Nota Fiscal</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Número da NF:</label>
                            <input
                                type="text"
                                value={formData.nf_number}
                                onChange={(e) => setFormData(prev => ({ ...prev, nf_number: e.target.value }))}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Data de Entrada:</label>
                            <input
                                type="date"
                                value={formData.date}
                                onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Fornecedor:</label>
                        <input
                            type="text"
                            value={formData.supplier}
                            onChange={(e) => setFormData(prev => ({ ...prev, supplier: e.target.value }))}
                            required
                            placeholder="Nome do fornecedor"
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Impostos (R$):</label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={formData.taxes}
                                onChange={(e) => setFormData(prev => ({ ...prev, taxes: e.target.value }))}
                                placeholder="Opcional"
                            />
                        </div>

                        <div className="form-group">
                            <label>Frete (R$):</label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={formData.shipping}
                                onChange={(e) => setFormData(prev => ({ ...prev, shipping: e.target.value }))}
                                placeholder="Opcional"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Observações:</label>
                        <textarea
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                        />
                    </div>

                    <div className="form-group">
                        <label>Adicionar Item:</label>
                        {error && <div className="error-message">{error}</div>}
                        <div className="add-item-row">
                            <input
                                type="text"
                                placeholder="Código do item"
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            />
                            <input
                                type="number"
                                min="1"
                                value={selectedQuantity}
                                onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                                placeholder="Qtd"
                            />
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={selectedPrice}
                                onChange={(e) => setSelectedPrice(e.target.value)}
                                placeholder="Preço R$"
                            />
                            <button type="button" onClick={handleAddItem}>
                                <FaPlus />
                            </button>
                        </div>
                    </div>

                    <div className="items-list">
                        <h3>Itens da Nota Fiscal</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantidade</th>
                                    <th>Preço Unit.</th>
                                    <th>Total</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.description}</td>
                                        <td>{item.quantity}</td>
                                        <td>R$ {item.price.toFixed(2)}</td>
                                        <td>R$ {(item.quantity * item.price).toFixed(2)}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="danger"
                                                onClick={() => handleRemoveItem(index)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-actions">
                        <button type="submit" className="success" disabled={processing}>
                            {processing ? 'Processando...' : 'Criar Nota Fiscal'}
                        </button>
                        <button type="button" onClick={onClose}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default InvoiceModal; 