import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ children, allowedGroups }) => {
  const { user, isAuthenticated } = useAuth();
  const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
  
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  
  if (!allowedGroups.some(group => userGroups.includes(group))) {
    return <Navigate to="/" />;
  }
  
  return children;
};

export default ProtectedRoute; 