import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { Auth, Amplify } from 'aws-amplify';
import './App.css';

import Sidebar from './components/Sidebar/Sidebar';
import Procedimentos from './components/Procedimentos';
import Historico from './components/History/Historico';
import Anamnese from './components/Anamnese/Anamnese';
import Pacientes from './components/Pacientes/Pacientes';
import Booking from './components/Booking/Booking';
import Config from './components/Config/Config';
import Caixa from './components/Caixa/Caixa';
import Estoque from './components/Estoque/Estoque';
import ProtectedRoute from './components/ProtectedRoute';
import { ROUTE_PERMISSIONS } from './config/permissions';


Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_vbA0WoIfI',
    userPoolWebClientId: '1mf0d541j8u21c47761cjcsv47',
    identityPoolId: null,
    oauth: {
      domain: 'patriciabandeirademelo.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
      responseType: 'code'
    },
  },
});

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
    } catch (err) {
      console.log(err);
      setUser(null);
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function signIn() {
    Auth.federatedSignIn();
  }

  if (!user) {
    return <button onClick={signIn}>Sign In</button>;
  }

  return (
    <Router>
      <div className="App">
        <div className="card">
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.anamnese}>
                  <Anamnese />
                </ProtectedRoute>
              } />
              <Route path="/protocolo" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.protocolo}>
                  <Procedimentos />
                </ProtectedRoute>
              } />
              <Route path="/agenda" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.agenda}>
                  <div className="agenda"><Booking /></div>
                </ProtectedRoute>
              } />
              <Route path="/historico" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.historico}>
                  <div className="historico"><Historico/></div>
                </ProtectedRoute>
              } />
              <Route path="/anamnese" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.anamnese}>
                  <Anamnese />
                </ProtectedRoute>
              } />
              <Route path="/pacientes" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.pacientes}>
                  <Pacientes />
                </ProtectedRoute>
              } />
              <Route path="/config" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.config}>
                  <Config />
                </ProtectedRoute>
              } />
              <Route path="/caixa" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.caixa}>
                  <Caixa />
                </ProtectedRoute>
              } />
              <Route path="/estoque" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.estoque}>
                  <Estoque />
                </ProtectedRoute>
              } />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;