import React, { useState, useEffect } from 'react';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import Spinner from '../Spinner/Spinner';
import { fetchAnamnesis } from '../api';

function AnamneseHistory() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [anamnesisData, setAnamnesisData] = useState([]);
  const [filteredAnamnesisData, setFilteredAnamnesisData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [showAnamnesisModal, setShowAnamnesisModal] = useState(false);
  const [selectedAnamnesis, setSelectedAnamnesis] = useState(null);

  useEffect(() => {
    const loadInitialAnamnesis = async () => {
      setIsInitialLoading(true);
      try {
        const initialData = await fetchAnamnesis();
        setAnamnesisData(initialData);
        setFilteredAnamnesisData(initialData);
      } catch (error) {
        console.error('Error fetching initial anamnesis:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    loadInitialAnamnesis();
  }, []);

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    
    setIsLoading(true);
    try {
      const filtered = await fetchAnamnesis(patient.pacientId);
      setFilteredAnamnesisData(filtered);
    } catch (error) {
      console.error('Error fetching anamnesis:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAnamnesis = (id) => {
    const anamnesis = filteredAnamnesisData.find(item => item.id === id);
    setSelectedAnamnesis(anamnesis.content);
    setShowAnamnesisModal(true);
  };

  const handleCloseModal = () => {
    setShowAnamnesisModal(false);
    setSelectedAnamnesis(null);
  };

  return (
    <div className="anamnesis-history-container">
      <PatientSearchSelect 
        onPatientSelect={handlePatientSelect} 
      />
      
      {selectedPatient && (
        <div className="selected-patient-info">
          <p>Paciente selecionado: {selectedPatient.Nome}</p>
        </div>
      )}
      
      {isInitialLoading || isLoading ? (
        <Spinner size="medium" />
      ) : (
        <table className="history-table">
          <thead>
            <tr>
              <th>Data</th>
              <th>Paciente</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredAnamnesisData.map((item) => (
              <tr key={item.id}>
                <td>{item.date}</td>
                <td>{item.patient}</td>
                <td>
                  <button 
                    className="view-anamnesis-btn"
                    onClick={() => handleViewAnamnesis(item.id)}
                  >
                    Ver anamnese
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Anamnesis Modal */}
      {showAnamnesisModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Anamnese</h2>
              <button className="close-button" onClick={handleCloseModal}>×</button>
            </div>
            <div className="modal-body">
              <pre className="anamnesis-text">{selectedAnamnesis}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnamneseHistory; 