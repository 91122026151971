import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
      setIsAuthenticated(true);
      setUserGroups(userData.signInUserSession?.accessToken?.payload['cognito:groups'] || []);
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
      setUserGroups([]);
    }
  };

  return {
    user,
    isAuthenticated,
    userGroups,
  };
}; 