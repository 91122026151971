import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaCheck } from 'react-icons/fa';
import { getStock, createStockChanges } from '../api';
import './Estoque.css';

function RequisitionModal({ show, onClose, requisition, onSubmit, onAttendRequisition }) {
    const [formData, setFormData] = useState({
        requester: '',
        notes: '',
        items: []
    });
    const [productId, setProductId] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (requisition) {
            setFormData({
                requester: requisition.requester,
                notes: requisition.notes,
                items: requisition.items
            });
        } else {
            setFormData({
                requester: '',
                notes: '',
                items: []
            });
        }
    }, [requisition]);

    // Add keyboard and click event handlers
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleAddItem = async () => {
        if (!productId) return;

        try {
            setError('');
            const stockData = await getStock(productId);
            
            if (!stockData || !stockData.items || stockData.items.length === 0) {
                setError('Item não encontrado no estoque');
                return;
            }

            const stockItem = stockData.items[0];
            setFormData(prev => ({
                ...prev,
                items: [
                    ...prev.items,
                    {
                        product_id: productId,
                        description: stockItem.description,
                        quantity: selectedQuantity
                    }
                ]
            }));

            setProductId('');
            setSelectedQuantity(1);
        } catch (err) {
            setError('Erro ao buscar item no estoque');
            console.error(err);
        }
    };

    const handleRemoveItem = (index) => {
        setFormData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.items.length === 0) {
            alert('Adicione pelo menos um item à requisição');
            return;
        }
        onSubmit(formData);
    };

    const handleAttendRequisition = async () => {
        try {
            setProcessing(true);
            setError('');

            // Create stock changes for each item in the requisition
            const changes = formData.items.map(item => ({
                product_id: item.product_id,
                quantity_change: -item.quantity, // Negative for removal
                type: 'out',
                nf_number: '-',
                requester: requisition.requester,
                notes: `Atendimento da requisição ${requisition.requisition_id}`,
                requisition_id: requisition.requisition_id,
                requisition_timestamp: requisition.timestamp
            }));

            await createStockChanges(changes);
            onAttendRequisition(requisition.requisition_id);
            onClose();
        } catch (err) {
            setError(err.message || 'Erro ao atender requisição');
            console.error(err);
        } finally {
            setProcessing(false);
        }
    };

    const handleOverlayClick = (e) => {
        // Close modal only if clicking directly on the overlay
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className="modal-overlay" 
            onClick={handleOverlayClick}
        >
            <div className="modal-content">
                <h2>{requisition ? 'Detalhes da Requisição' : 'Nova Requisição'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Requisitante:</label>
                        <input
                            type="text"
                            value={formData.requester}
                            onChange={(e) => setFormData(prev => ({ ...prev, requester: e.target.value }))}
                            required
                            disabled={!!requisition}
                        />
                    </div>

                    <div className="form-group">
                        <label>Observações:</label>
                        <textarea
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                            disabled={!!requisition}
                        />
                    </div>

                    {!requisition && (
                        <div className="form-group">
                            <label>Adicionar Item:</label>
                            {error && <div className="error-message">{error}</div>}
                            <div className="add-item-row">
                                <input
                                    type="text"
                                    placeholder="Código do item"
                                    value={productId}
                                    onChange={(e) => setProductId(e.target.value)}
                                />
                                <input
                                    type="number"
                                    min="1"
                                    value={selectedQuantity}
                                    onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                                />
                                <button type="button" onClick={handleAddItem}>
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="items-list">
                        <h3>Itens da Requisição</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantidade</th>
                                    {!requisition && <th>Ações</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {formData.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.description}</td>
                                        <td>{item.quantity}</td>
                                        {!requisition && (
                                            <td>
                                                <button
                                                    type="button"
                                                    className="danger"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-actions">
                        {!requisition && (
                            <button type="submit" className="success">
                                Criar Requisição
                            </button>
                        )}
                        {requisition && requisition.status === 'Aberta' && (
                            <button 
                                type="button" 
                                className="success"
                                onClick={handleAttendRequisition}
                                disabled={processing}
                            >
                                <FaCheck /> {processing ? 'Processando...' : 'Atender Requisição'}
                            </button>
                        )}
                        <button type="button" onClick={onClose}>
                            {requisition ? 'Fechar' : 'Cancelar'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RequisitionModal; 