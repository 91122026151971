import React, { useState, useEffect } from 'react';
import './Estoque.css';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { fetchStock, getStockMovements } from '../api';
import StockModal from './StockModal';

function Estoque() {
    const [activeTab, setActiveTab] = useState('stock');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('add');
    const [stockItems, setStockItems] = useState([]);
    const [stockMovements, setStockMovements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paginationToken, setPaginationToken] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [movementsLoading, setMovementsLoading] = useState(false);
    const [movementsError, setMovementsError] = useState(null);
    const [movementsPaginationToken, setMovementsPaginationToken] = useState(null);
    const [hasMoreMovements, setHasMoreMovements] = useState(true);

    const loadStockData = async (token = null) => {
        try {
            setLoading(true);
            setError(null);
            const data = await fetchStock(token);
            
            if (token) {
                setStockItems(prev => [...prev, ...data.items]);
            } else {
                setStockItems(data.items);
            }
            
            setHasMore(!!data.paginationToken);
            setPaginationToken(data.paginationToken);
        } catch (err) {
            setError('Failed to load stock data');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const loadStockMovements = async (token = null) => {
        try {
            setMovementsLoading(true);
            setMovementsError(null);
            const data = await getStockMovements(token);
            
            if (token) {
                setStockMovements(prev => [...prev, ...data.items]);
            } else {
                setStockMovements(data.items);
            }
            
            setHasMoreMovements(!!data.paginationToken);
            setMovementsPaginationToken(data.paginationToken);
        } catch (err) {
            setMovementsError('Failed to load stock movements');
            console.error(err);
        } finally {
            setMovementsLoading(false);
        }
    };

    useEffect(() => {
        loadStockData();
        loadStockMovements();
    }, []);

    const loadMore = () => {
        if (!loading && hasMore) {
            loadStockData(paginationToken);
        }
    };

    const loadMoreMovements = () => {
        if (!movementsLoading && hasMoreMovements) {
            loadStockMovements(movementsPaginationToken);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleStockChange = async (stockChangeData) => {
        try {
            // Add the new movement to the list
            const newMovement = {
                ...stockChangeData,
                change_id: Date.now().toString(),
                timestamp: new Date().toISOString()
            };
            setStockMovements(prev => [newMovement, ...prev]);

            // Refresh both stock data and movements
            await Promise.all([
                loadStockData(),
                loadStockMovements()
            ]);
            handleCloseModal();
        } catch (err) {
            setError('Failed to process stock change');
            console.error(err);
        }
    };

    return (
        <div className="estoque-container">
            <div className="estoque-tabs">
                <button 
                    className={`tab-button ${activeTab === 'stock' ? 'active' : ''}`}
                    onClick={() => setActiveTab('stock')}
                >
                    Estoque Atual
                </button>
                <button 
                    className={`tab-button ${activeTab === 'movements' ? 'active' : ''}`}
                    onClick={() => setActiveTab('movements')}
                >
                    Movimentações
                </button>
            </div>

            {activeTab === 'stock' && (
                <>
                    <div className="table-actions">
                        <button className="success" onClick={() => handleShowModal('add')}>
                            <FaPlus /> Adicionar ao Estoque
                        </button>
                        <button className="danger" onClick={() => handleShowModal('remove')}>
                            <FaMinus /> Remover do Estoque
                        </button>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantidade</th>
                                <th>Código do item</th>
                                <th>Ideal</th>
                                <th>Minimo</th>
                                <th>Preço</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.description}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.product_id}</td>
                                    <td>{item.ideal}</td>
                                    <td>{item.min}</td>
                                    <td>R$ {item.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {loading && <div className="loading">Loading...</div>}
                    {hasMore && !loading && (
                        <button className="load-more" onClick={loadMore}>
                            Carregar mais
                        </button>
                    )}
                </>
            )}

            {activeTab === 'movements' && (
                <>
                    {movementsError && <div className="error-message">{movementsError}</div>}
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Item</th>
                                <th>Tipo</th>
                                <th>Quantidade</th>
                                <th>NF</th>
                                <th>Requisitante</th>
                                <th>Observações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockMovements.map((movement) => (
                                <tr key={movement.change_id}>
                                    <td>{new Date(movement.timestamp).toLocaleDateString()}</td>
                                    <td>{movement.product_id}</td>
                                    <td>{movement.type === 'in' ? 'Entrada' : 'Saída'}</td>
                                    <td>{Math.abs(movement.quantity_change)}</td>
                                    <td>{movement.nf_number}</td>
                                    <td>{movement.requester}</td>
                                    <td>{movement.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {movementsLoading && <div className="loading">Loading...</div>}
                    {hasMoreMovements && !movementsLoading && (
                        <button className="load-more" onClick={loadMoreMovements}>
                            Carregar mais
                        </button>
                    )}
                </>
            )}

            <StockModal
                show={showModal}
                onClose={handleCloseModal}
                type={modalType}
                onSubmit={handleStockChange}
            />
        </div>
    );
}

export default Estoque; 