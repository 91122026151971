import React, { useState } from 'react';
import { resetConfigCache } from '../api';
import './Config.css';

function Config() {
  const [isCacheReset, setIsCacheReset] = useState(false);

  const handleResetCache = () => {
    resetConfigCache();
    setIsCacheReset(true);
    
    // Reset the notification after 3 seconds
    setTimeout(() => {
      setIsCacheReset(false);
    }, 3000);
  };

  return (
    <div className="config-container">
      <h2>Configurações</h2>
      
      <div className="config-section">
        <h3>Cache de Configurações</h3>
        <div className="cache-reset-container">
          <button 
            onClick={handleResetCache} 
            className="cache-reset-button"
          >
            Limpar Cache de Configurações
          </button>
          
          {isCacheReset && (
            <div className="cache-reset-notification">
              Cache resetado com sucesso!
            </div>
          )}
        </div>
        <p className="config-description">
          Limpe o cache de configurações se você suspeitar que os dados 
          de procedimentos ou profissionais estão desatualizados.
        </p>
      </div>
    </div>
  );
}

export default Config; 