import React, { useState, useEffect } from 'react';
import { fetchBookings, fetchProfessionals } from '../api';
import { ClipLoader } from 'react-spinners';
import './Caixa.css';

function ViewBookingModal({ booking, showModal, onClose }) {
  useEffect(() => {
    if (showModal) {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal, onClose]);

  if (!showModal || !booking) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content dark" onClick={e => e.stopPropagation()}>
        <h2>Detalhes do Agendamento</h2>
        <div className="booking-details">
          <div className="form-group">
            <label>Paciente</label>
            <div className="detail-value">{booking.paciente}</div>
          </div>

          <div className="form-group">
            <label>Data e Hora</label>
            <div className="detail-value">
              {new Date(booking.dataAtendimento).toLocaleString()}
            </div>
          </div>

          <div className="form-group">
            <label>Duração (minutos)</label>
            <div className="detail-value">{booking.duration}</div>
          </div>

          <div className="form-group">
            <label>Procedimentos</label>
            <div className="detail-value">
              {booking.procedimentos?.map((proc, i) => (
                <div key={i}>{proc.nome}</div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>Valor Total</label>
            <div className="detail-value">R$ {(parseFloat(booking.valorTotal) || 0).toFixed(2)}</div>
          </div>

          <div className="form-group">
            <label>Comissão</label>
            <div className="detail-value">R$ {(booking.comission || 0).toFixed(2)}</div>
          </div>

          <div className="form-group">
            <label>Status do Atendimento</label>
            <div className="detail-value">{booking.status || 'Agendado'}</div>
          </div>

          <div className="form-group">
            <label>Forma de Pagamento</label>
            <div className="detail-value">{booking.paymentMethod || 'Não definido'}</div>
          </div>

          <div className="form-group">
            <label>Status do Pagamento</label>
            <div className="detail-value">{booking.paymentStatus || 'Pendente'}</div>
          </div>

          {booking.paymentDate && (
            <div className="form-group">
              <label>Data do Pagamento</label>
              <div className="detail-value">
                {new Date(booking.paymentDate).toLocaleString()}
              </div>
            </div>
          )}

          {booking.observacao && (
            <div className="form-group">
              <label>Observação</label>
              <div className="detail-value">{booking.observacao}</div>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button className="action-button" onClick={onClose}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

function Caixa() {
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [searchDate, setSearchDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchProfessionals()
      .then(data => {
        const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setProfessionals(professionalsList);
      })
      .catch(error => console.error('Error fetching professionals:', error));

    // Load today's bookings by default
    loadBookings({ date: searchDate });
  }, []);

  const loadBookings = async (queryParams = {}) => {
    try {
      setBookingsLoading(true);
      const bookingsData = await fetchBookings(queryParams);
      setBookings(bookingsData);
      console.log(bookingsData);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setBookingsLoading(false);
    }
  };

  const handleSearch = () => {
    if (!searchDate) {
      alert('Por favor, selecione uma data');
      return;
    }

    const queryParams = {
      date: searchDate
    };

    loadBookings(queryParams);
  };

  const calculateTotalValue = (procedures) => {
    return procedures.reduce((total, proc) => total + proc.valor, 0);
  };

  const groupBookingsByProfessional = () => {
    const groupedBookings = {};
    
    bookings
      .filter(booking => booking.status === 'Agendado' || booking.status === 'Realizado')
      .forEach(booking => {
        const profId = booking.profissional;
        if (!groupedBookings[profId]) {
          groupedBookings[profId] = {
            bookings: [],
            total: 0,
            totalComission: 0
          };
        }
        const bookingTotal = calculateTotalValue(booking.procedimentos);
        groupedBookings[profId].bookings.push(booking);
        groupedBookings[profId].total += bookingTotal;
        groupedBookings[profId].totalComission += booking.comission || 0;
      });
    
    return groupedBookings;
  };

  const getProfessionalName = (professionalId) => {
    const professional = professionals.find(p => p.id === professionalId);
    return professional ? professional.name : professionalId;
  };

  const handleRowClick = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const renderBookingsList = () => {
    const groupedBookings = groupBookingsByProfessional();

    return (
      <div className="caixa-container">
        <div className="caixa-search-filters">
          <div className="search-date">
            <label>Data</label>
            <input 
              type="date" 
              value={searchDate} 
              onChange={(e) => setSearchDate(e.target.value)}
              className="date-input"
              required
            />
          </div>

          <button 
            className="search-button" 
            onClick={handleSearch}
          >
            Buscar
          </button>
        </div>

        {bookingsLoading ? (
          <div className="loading-container">
            <ClipLoader size={50} color={"#4a90e2"} />
          </div>
        ) : (
          <div className="bookings-list">
            {Object.entries(groupedBookings).map(([profId, data]) => (
              <div key={profId} className="professional-group">
                <h3>{getProfessionalName(profId)}</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Paciente</th>
                      <th>Procedimentos</th>
                      <th>Valor</th>
                      <th>Comissão</th>
                      <th>Forma de Pagamento</th>
                      <th>Pagamento</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.bookings.map((booking, index) => {
                      const isCompleted = booking.status === 'Realizado';
                      const bookingTotal = parseInt(booking.valorTotal, 10);

                      return (
                        <tr 
                          key={index}
                          onClick={() => handleRowClick(booking)}
                          style={{ 
                            backgroundColor: isCompleted ? '#2e7d32' : 'inherit',
                            color: isCompleted ? '#ffffff' : 'inherit',
                            cursor: 'pointer'
                          }}
                        >
                          <td>{booking.paciente}</td>
                          <td>
                            {booking.procedimentos?.map((proc, i) => (
                              <div key={i}>{proc.nome}</div>
                            ))}
                          </td>
                          <td>R$ {(parseFloat(bookingTotal) || 0).toFixed(2)}</td>
                          <td>R$ {(booking.comission || 0).toFixed(2)}</td>
                          <td>{booking.paymentMethod || '-'}</td>
                          <td>{booking.paymentStatus || 'Pendente'}</td>
                          <td>{booking.status || 'Agendado'}</td>
                        </tr>
                      );
                    })}
                    <tr className="total-row">
                      <td colSpan="2"><strong>Total do Profissional</strong></td>
                      <td><strong>R$ {(data.bookings.reduce((sum, booking) => sum + (parseFloat(booking.valorTotal) || 0), 0)).toFixed(2)}</strong></td>
                      <td><strong>R$ {(data.totalComission || 0).toFixed(2)}</strong></td>
                      <td colSpan="3"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
            {Object.keys(groupedBookings).length > 0 && (
              <div className="grand-total">
                <h3>Total Geral</h3>
                <div className="total-value">
                  <div>
                    Valor: R$ {(Object.values(groupedBookings)
                      .reduce((total, data) => 
                        total + data.bookings.reduce((sum, booking) => 
                          sum + (parseFloat(booking.valorTotal) || 0), 0), 0))
                      .toFixed(2)}
                  </div>
                  <div>
                    Comissões: R$ {(Object.values(groupedBookings)
                      .reduce((total, data) => total + (data.totalComission || 0), 0))
                      .toFixed(2)}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <ViewBookingModal
          booking={selectedBooking}
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
            setSelectedBooking(null);
          }}
        />
      </div>
    );
  };

  return renderBookingsList();
}

export default Caixa; 