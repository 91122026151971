import React, { useState, useEffect } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { ClipLoader } from 'react-spinners';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import "./Anamnese.css"
import { transcribeAudio, generateAnamnesis } from '../api';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;
const CLAUDE_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;


function Transcription() {
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [anamnesis, setAnamnesis] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedTranscription, setEditedTranscription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAnamnesis, setIsLoadingAnamnesis] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);

  useEffect(() => {
    // console.log('API Keys available:', {
    //   groq: !!GROQ_API_KEY,
    //   claude: !!CLAUDE_API_KEY
    // });
  }, []);

  const handleGenerateAnamnesis = async (transcriptionText) => {
    setIsLoadingAnamnesis(true);
    try {
      const anamnesisText = await generateAnamnesis(transcriptionText, selectedPatient);
      setAnamnesis(anamnesisText);
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to generate anamnesis. Please try again.');
    } finally {
      setIsLoadingAnamnesis(false);
    }
  };

  navigator.mediaDevices.getUserMedia({ audio: true },
    () => setIsBlocked(false),
    () => setIsBlocked(true)
  );

  const startRecording = () => {
    setError(null);
    if (isBlocked) {
      setError('Microphone permission denied');
      return;
    }
    
    setTranscription('');
    setEditedTranscription('');
    setAnamnesis('');
    
    Mp3Recorder.start()
      .then(() => setIsRecording(true))
      .catch((e) => {
        console.error('Recording error:', e);
        setError('Failed to start recording');
      });
  };

  const stopRecording = () => {
    if (!GROQ_API_KEY) {
      setError('API key not found. Please check your environment variables.');
      return;
    }

    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        setBlobURL(URL.createObjectURL(blob));
        setIsRecording(false);
        setIsLoading(true);
        setError(null);

        try {
          const audioFile = new File([blob], 'recording.mp3', {
            type: 'audio/mpeg',
            lastModified: new Date().getTime()
          });

          const transcriptionText = await transcribeAudio(audioFile, GROQ_API_KEY);
          setTranscription(transcriptionText);
          setEditedTranscription(transcriptionText);

          // Generate anamnesis after successful transcription
          await handleGenerateAnamnesis(transcriptionText);

        } catch (error) {
          console.error('Transcription error:', error.response || error);
          setError(error.response?.data?.error?.message || 'Failed to transcribe audio. Please try again.');
        } finally {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error('MP3 conversion error:', e);
        setError('Failed to process audio');
        setIsLoading(false);
      });
  };

  const handleEdit = () => setIsEditing(true);
  
  const handleSave = () => {
    setTranscription(editedTranscription);
    setIsEditing(false);

    // Prepare transcription data for future saving
    if (selectedPatient && transcription && anamnesis) {
      const transcriptionRecord = {
        patientId: selectedPatient.pacientId,
        patientName: selectedPatient.Nome,
        transcription: editedTranscription,
        anamnesis: anamnesis,
        timestamp: new Date().toISOString()
      };

      setTranscriptionData(transcriptionRecord);
      console.log('Transcription Data Prepared:', transcriptionRecord);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
  };

  return (
    <div className="transcription-container">
      <div className="audio-controls">
        <div className="audio-controls-wrapper">
          <button 
            className="record-button" 
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? 'Parar Gravação' : 'Gravar Áudio'}
          </button>
          
          <PatientSearchSelect 
            onPatientSelect={handlePatientSelect} 
          />
        </div>
        
        {isRecording && <span className="recording-indicator">Gravando...</span>}
        
        {blobURL && (
          <audio className="audio-player" src={blobURL} controls />
        )}
      </div>

      <div className="transcription-area">
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {(isLoading || isLoadingAnamnesis) ? (
          <div className="loading-container">
            <ClipLoader color="#FFFFFF" size={50} />
            <p>{isLoadingAnamnesis ? 'Gerando anamnese...' : 'Transcrevendo áudio...'}</p>
          </div>
        ) : (
          <>
            {selectedPatient && (
              <div className="content-section">
                <h3>Paciente</h3>
                <div className="patient-info">
                  <p><strong>Nome:</strong> {selectedPatient.Nome}</p>
                  {selectedPatient.CPF && (
                    <p><strong>CPF:</strong> {selectedPatient.CPF}</p>
                  )}
                </div>
              </div>
            )}

            {transcription && (
              <div className="content-section">
                <h3>Transcrição Original</h3>
                {isEditing ? (
                  <div className="edit-container">
                    <textarea
                      value={editedTranscription}
                      onChange={(e) => setEditedTranscription(e.target.value)}
                      className="edit-textarea"
                    />
                    <button className="action-button" onClick={handleSave}>
                      Salvar
                    </button>
                  </div>
                ) : (
                  <>
                    <p className="transcription-text">{transcription}</p>
                    <div className="action-buttons">
                      <button className="action-button" onClick={handleEdit}>
                        Editar
                      </button>
                      <button className="action-button" onClick={() => copyToClipboard(transcription)}>
                        Copiar
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}

            {anamnesis && (
              <div className="content-section">
                <h3>Anamnese</h3>
                <div className="anamnesis-text">
                  <pre>{anamnesis}</pre>
                  <div className="action-buttons">
                    <button className="action-button" onClick={() => copyToClipboard(anamnesis)}>
                      Copiar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Transcription;