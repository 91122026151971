import React, { useState } from 'react';
import AnamneseHistory from './AnamneseHistory';
import PacientHistory from './PacientHistory';
import ProfessionalHistory from './ProfessionalHistory';
import DateHistory from './DateHistory';
import './Historico.css';

function Historico() {
  const [activeTab, setActiveTab] = useState('anamnesis');

  const tabs = [
    { id: 'anamnesis', label: 'Anamnese' },
    { id: 'patient', label: 'Pacientes' },
    { id: 'professional', label: 'Profissionais' },
    { id: 'date', label: 'Por Data' },
  ];

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'anamnesis':
        return <AnamneseHistory />;
      case 'patient':
        return <PacientHistory />;
      case 'professional':
        return <ProfessionalHistory />;
      case 'date':
        return <DateHistory />;
      default:
        return <AnamneseHistory />;
    }
  };

  return (
    <div className="historico-container">
      <div className="tabs-container">
        <div className="tabs-list">
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      <div className="tab-content">
        {renderActiveTab()}
      </div>
    </div>
  );
}

export default Historico;


