import React, { useState, useMemo } from 'react';
import { useTable, useFilters } from 'react-table';
import { fetchEvents } from '../api';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';

function TextFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      className="filter-input"
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder="Filtrar..."
    />
  );
}

function PacientHistory() {
  const [events, setEvents] = useState([]);
  const [patient, setPatient] = useState(null);

  const handlePatientSelect = async (selectedPatient) => {
    setPatient(selectedPatient);
    
    const queryParams = {
      patient: selectedPatient.Nome
    };

    try {
      const fetchedEvents = await fetchEvents(queryParams);
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error fetching patient events:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Título',
        accessor: data => data.title ? data.title.S : " ",
        Filter: TextFilter,
      },
      {
        Header: 'Dia',
        accessor: data => data.start ? data.start.S.split('T')[0] : " ",
        Filter: TextFilter,
      },
      {
        Header: 'Horário',
        accessor: data => data.start ? data.start.S.split('T')[1] : " ",
        Filter: TextFilter,
      },
      {
        Header: 'Profissional',
        accessor: data => data.professional ? data.professional.S : " ",
        Filter: TextFilter,
      },
      {
        Header: 'Procedimento',
        accessor: data => data.procedure ? data.procedure.S : " ",
        Filter: TextFilter,
      },
    ],
    []
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: events }, useFilters);

  return (
    <div className="pacient-history-container">
      <div className="search-controls">
        <PatientSearchSelect 
          onPatientSelect={handlePatientSelect} 
        />
        
        {patient && (
          <div className="selected-patient-info">
            <p>Paciente selecionado: {patient.Nome}</p>
          </div>
        )}
      </div>

      {rows.length > 0 ? (
        <table {...getTableProps()} className="history-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="no-data">
          <p>Nenhum dado disponível</p>
        </div>
      )}
    </div>
  );
}

export default PacientHistory; 