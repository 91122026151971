import axios from 'axios';

  // export function addEvent(newEvent) {
  //   Events.push(newEvent);
  // }
  
  // export function updateEvent(updatedEvent) {
  //   const index = Events.findIndex(event => event.eventId === updatedEvent.eventId);
  //   if (index !== -1) {
  //     Events[index] = updatedEvent;
  //   }
  // }
  
  // export function deleteEvent(eventId) {
  //   const index = Events.findIndex(event => event.eventId === eventId);
  //   if (index !== -1) {
  //     Events.splice(index, 1);
  //   }
  // }

const BASE_URL = 'https://upqsv1xash.execute-api.us-east-1.amazonaws.com/prod/';

export const fetchProcedimentos = async () => {
  const cachedProcedures = localStorage.getItem('cached_procedimentos');
  const cachedTimestamp = localStorage.getItem('procedimentos_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProcedures && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProcedures);
    }
  }

  try {
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        config_id: '1',
      }),
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_procedimentos', JSON.stringify(data));
    localStorage.setItem('procedimentos_cache_timestamp', Date.now().toString());
    
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Procedimentos:', error);
    // Return cached data if available in case of network error
    const cachedProcedures = localStorage.getItem('cached_procedimentos');
    return cachedProcedures ? JSON.parse(cachedProcedures) : [];
  }
};

export const fetchProfessionals = async () => {
  const cachedProfessionals = localStorage.getItem('cached_professionals');
  const cachedTimestamp = localStorage.getItem('professionals_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProfessionals && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProfessionals);
    }
  }

  try {
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        config_id: '2',
      }),
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_professionals', JSON.stringify(data));
    localStorage.setItem('professionals_cache_timestamp', Date.now().toString());
    
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Professionals:', error);
    // Return cached data if available in case of network error
    const cachedProfessionals = localStorage.getItem('cached_professionals');
    return cachedProfessionals ? JSON.parse(cachedProfessionals) : [];
  }
};

export const fetchPatients = async (searchTerm = '') => {
  try {
    const response = await fetch(`${BASE_URL}get-pacientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchTerm ? { search: searchTerm } : {}),
    });
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Patients:', error);
    return [];
  }
};

export const createPatient = async (patient) => {
  try {
    const response = await fetch(`${BASE_URL}create-paciente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating patient:', error);
    throw error;
  }
};

export const createItem = async (item) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};

export const deleteItem = async (eventId) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item: eventId }),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const fetchEvents = async (queryParams = {}) => {
  try {
    const url = new URL(`${BASE_URL}get-history`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Events:', error);
    return [];
  }
};

export const fetchAnamnesis = async (patientId = null) => {
  try {
    const response = await fetch(`${BASE_URL}get-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patientId ? { patient_id: patientId } : {}),
    });

    const data = await response.json();
    
    return (data.anamneses || []).map(item => ({
      id: item.anamnese_id,
      date: item.date || 'N/A',
      patient: item.patient_name || 'N/A',
      patientId: item.patient_id || 'N/A',
      content: item.anamnesis_text
    }));
  } catch (error) {
    console.error('Error fetching Anamnesis:', error);
    return [];
  }
};

export const sendToReception = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}send-to-reception`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error sending to reception:', error);
    throw error;
  }
};

export const createBudget = async (budgetData) => {
  try {
    const response = await fetch(`${BASE_URL}create-budget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(budgetData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating budget:', error);
    throw error;
  }
};

export const transcribeAudio = async (audioFile, apiKey) => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', 'whisper-large-v3');
    formData.append('language', 'pt');
    formData.append('prompt', 'Transcrição de áudio em português brasileiro para uma clínica de dermatologia');

    const response = await axios.post(
      'https://api.groq.com/openai/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data.text;
  } catch (error) {
    console.error('Transcription error:', error.response || error);
    throw error;
  }
};

export const generateAnamnesis = async (transcriptionText, selectedPatient) => {
  try {
    const response = await fetch(`${BASE_URL}create-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: transcriptionText,
        patient: selectedPatient || null
      })
    });

    const data = await response.json();
    
    return data.anamnesis || '';

  } catch (error) {
    console.error('Anamnesis generation error:', error.response?.data || error);
    throw error;
  }
};

export const resetConfigCache = () => {
  // Remove cached procedures
  localStorage.removeItem('cached_procedimentos');
  localStorage.removeItem('procedimentos_cache_timestamp');
  
  // Remove cached professionals
  localStorage.removeItem('cached_professionals');
  localStorage.removeItem('professionals_cache_timestamp');
  
  console.log('Configuration cache has been reset');
};

export const createBooking = async (bookingData) => {
  try {
    const response = await fetch(`${BASE_URL}create-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const fetchBookings = async (queryParams = {}) => {
  try {
    const url = new URL(`${BASE_URL}get-booking`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParams)
    });
    
    const data = await response.json();
    return data.bookings || [];
  } catch (error) {
    console.error('Error fetching Bookings:', error);
    return [];
  }
};

export const updateBooking = async (updates) => {
  try {
    const response = await fetch(`${BASE_URL}update-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });

    if (!response.ok) {
      throw new Error('Failed to update booking');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating booking:', error);
    throw error;
  }
};



export const getStock = async (product_id) => {
    try {
        const url = new URL(`${BASE_URL}get-stock`);
        if (product_id) {
            url.searchParams.append('product_id', product_id);
        }

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch stock item');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching stock item:', error);
        throw error;
    }
};

export const updateStock = async (stockData) => {
    try {
        const response = await fetch(`${BASE_URL}create-stock-change`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(stockData)
        });
        
        if (!response.ok) {
            throw new Error('Failed to update stock');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error updating stock:', error);
        throw error;
    }
};


export const fetchStock = async (paginationToken = null) => {
  try {
    const url = new URL(`${BASE_URL}get-stock`);
    if (paginationToken) {
      url.searchParams.append('pagination_token', paginationToken);
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return {
      items: data.items || [],
      count: data.count || 0,
      paginationToken: data.pagination_token
    };
  } catch (error) {
    console.error('Error fetching stock:', error);
    throw error;
  }
};

export const getStockMovements = async (paginationToken = null) => {
    try {
        const url = new URL(`${BASE_URL}get-stock-change`);
        if (paginationToken) {
            url.searchParams.append('pagination_token', paginationToken);
        }

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch stock movements');
        }
        const data = await response.json();
        return {
          items: data.items || [],
          count: data.count || 0,
          paginationToken: data.pagination_token
        };
      } catch (error) {
        console.error('Error fetching stock movements:', error);
        throw error;
    }
}
