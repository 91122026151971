import React from 'react';

function ProfessionalHistory() {
  return (
    <div className="professional-history-container">
      <div className="professional-search">
        <div className="no-data">
          <h3>Busca por profissional ainda não disponível.</h3>
          <p>Esta funcionalidade será implementada em breve.</p>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalHistory; 