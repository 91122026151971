// Define user groups
export const USER_GROUPS = {
    ADMIN: 'Admin',
    ATENDIMENTOS: 'Atendimentos',
    CAIXA: 'Caixa'
};

// Define route permissions
export const ROUTE_PERMISSIONS = {
    anamnese: [USER_GROUPS.ADMIN],
    historico: [USER_GROUPS.ADMIN],
    protocolo: [USER_GROUPS.ADMIN],
    agenda: [USER_GROUPS.ADMIN, USER_GROUPS.ATENDIMENTOS],
    pacientes: [USER_GROUPS.ADMIN, USER_GROUPS.ATENDIMENTOS],
    caixa: [USER_GROUPS.ADMIN, USER_GROUPS.CAIXA],
    config: [USER_GROUPS.ADMIN, USER_GROUPS.CAIXA, USER_GROUPS.ATENDIMENTOS],
    estoque: [USER_GROUPS.ADMIN, USER_GROUPS.CAIXA]
}; 