// src/components/Pacientes.js
import React, { useState } from 'react';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import { createPatient } from '../api';
import './Pacientes.css';
import { FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

function Pacientes() {
  const [patient, setPatient] = useState({
    Aniversario: '',
    CEP: '',
    Cidade: '',
    DtEntrada: new Date().toISOString().split('T')[0],
    Endereco: '',
    Estado: '',
    Fone: '',
    Nome: '',
    // Profissao: '',
    Sexo: '',
    Peso: '',
    Altura: '',
    pacientId: Math.random().toString(36).substring(2, 18)
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  const handlePatientSelect = (selectedPatient) => {
    setSelectedPatient(selectedPatient);
  }; 

  function generatePatientId() {
    return Math.random().toString(36).substring(2, 11).toUpperCase();
  }

  const handleInputChange = (event) => {
    setPatient({
      ...patient,
      [event.target.name]: event.target.value
    });
  };

  const handlePatientAdd = async () => {
    setLoading(true);
    try {
      const createdPatient = await createPatient(patient);
      console.log("createdPatient", createdPatient);
      setMessage({ text: 'Paciente criado com sucesso', type: 'success' });
      setPatient({
        Aniversario: '',
        CEP: '',
        Cidade: '',
        DtEntrada: new Date().toISOString().split('T')[0],
        Endereco: '',
        Estado: '',
        Fone: '',
        Nome: '',
        // Profissao: '',
        Sexo: '',
        Peso: '',
        Altura: '',
        pacientId: generatePatientId()
      });
    } catch (error) {
      setMessage({ text: 'Erro ao criar paciente', type: 'error' });
    }
    setLoading(false);
  };

  return (
    <div className="pacientes-container">
      <div className="search-section">
        <h2 className="section-title">Buscar ficha de paciente</h2>
        <PatientSearchSelect onPatientSelect={setSelectedPatient} />
        
        {selectedPatient && (
          <div className="patient-info">
            <h3 className="section-title">Informações do Paciente</h3>
            <p><strong>Nome:</strong> {selectedPatient.Nome}</p>
            <p><strong>Aniversário:</strong> {selectedPatient.Aniversario}</p>
            <p><strong>Estado:</strong> {selectedPatient.Estado}</p>
            <p><strong>Cidade:</strong> {selectedPatient.Cidade}</p>
            <p><strong>Endereço:</strong> {selectedPatient.Endereco}</p>
            <p><strong>CEP:</strong> {selectedPatient.CEP}</p>
            <p><strong>Data de Entrada:</strong> {selectedPatient.DtEntrada}</p>
            <p><strong>Telefone:</strong> {selectedPatient.Fone}</p>
            {/* <p><strong>Profissão:</strong> {selectedPatient.Profissao}</p> */}
            <p><strong>Sexo:</strong> {selectedPatient.Sexo}</p>
            <p><strong>Peso (kg):</strong> {selectedPatient.Peso}</p>
            <p><strong>Altura (cm):</strong> {selectedPatient.Altura}</p>
          </div>
        )}
      </div>

      <div className="add-patient-section">
        <h2 className="section-title">Cadastrar novo Paciente</h2>
        <form className="form-grid">
          <div className="form-group">
            <label>Nome</label>
            <input
              className="form-input"
              name="Nome"
              value={patient.Nome}
              onChange={handleInputChange}
              placeholder="Nome completo"
            />
          </div>
          
          <div className="form-group">
            <label>Aniversário</label>
            <input
              className="form-input"
              type="date"
              name="Aniversario"
              value={patient.Aniversario}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Estado</label>
            <input
              className="form-input"
              name="Estado"
              value={patient.Estado}
              onChange={handleInputChange}
              placeholder="Estado"
            />
          </div>

          <div className="form-group">
            <label>Cidade</label>
            <input
              className="form-input"
              name="Cidade"
              value={patient.Cidade}
              onChange={handleInputChange}
              placeholder="Cidade"
            />
          </div>

          <div className="form-group">
            <label>Endereço</label>
            <input
              className="form-input"
              name="Endereco"
              value={patient.Endereco}
              onChange={handleInputChange}
              placeholder="Endereço completo"
            />
          </div>

          <div className="form-group">
            <label>CEP</label>
            <input
              className="form-input"
              type="number"
              name="CEP"
              value={patient.CEP}
              onChange={handleInputChange}
              placeholder="00000-000"
            />
          </div>

          <div className="form-group">
            <label>Data de Entrada</label>
            <input
              className="form-input"
              type="date"
              name="DtEntrada"
              value={patient.DtEntrada}
              onChange={handleInputChange}
              onClick={(e) => e.target.showPicker()}
            />
          </div>

          <div className="form-group">
            <label>Telefone</label>
            <input
              className="form-input"
              name="Fone"
              value={patient.Fone}
              onChange={handleInputChange}
              placeholder="(00) 00000-0000"
            />
          </div>

          {/* <div className="form-group">
            <label>Profissão</label>
            <input
              className="form-input"
              name="Profissao"
              value={patient.Profissao}
              onChange={handleInputChange}
              placeholder="Profissão"
            />
          </div> */}

          <div className="form-group">
            <label>Sexo</label>
            <select
              className="form-input"
              name="Sexo"
              value={patient.Sexo}
              onChange={handleInputChange}
            >
              <option value="" disabled>Selecione o sexo</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>

          <div className="form-group">
            <label>Peso (kg)</label>
            <input
              className="form-input"
              type="number"
              name="Peso"
              value={patient.Peso}
              onChange={handleInputChange}
              placeholder="Peso em kg"
              step="0.1"
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Altura (cm)</label>
            <input
              className="form-input"
              type="number"
              name="Altura"
              value={patient.Altura}
              onChange={handleInputChange}
              placeholder="Altura em centímetros"
              step="1"
              min="0"
            />
          </div>
        </form>

        <button
          className="submit-button"
          onClick={handlePatientAdd}
          disabled={loading}
        >
          {loading ? (
            <>
              <FaSpinner className="icon-spin" /> Adicionando...
            </>
          ) : (
            'Adicionar Paciente'
          )}
        </button>

        {message.text && (
          <div className={`message ${message.type === 'success' ? 'success-message' : 'error-message'}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
}

export default Pacientes;